/*————————————————————————————————————————————————————————————————————————————*/
/* Variable initialization */
/* establishing some standard values to provide consistency across the app */

:root {
  --primary: #2a7de1 /*hsl(213, 75%, 52%)*/;
  --primary-glow: hsl(
    213 75% 50% / 0.04
  ); /* e.g. if primary was #f50057, primary-glow should be rgba(245, 0, 87, 0.04) */

  --red: #e74c3c;

  --menu-item-hover: hsl(210 80% 96%); /* #edf5fd copied from https://npmjs.org/react-select default */
  --menu-item-active: hsl(210 50% 90%);

  --border-radius: 6px;

  --input-outline-width: 1px; /* Material UI default */
  --input-outline-color: #e5e5e5;
  --input-outline-hover-color: #b3b3b3;
  --input-outline-focus-color: var(--primary);
  --input-outline-focus-width: 2px; /* Material UI default */

  --gray-icon-in-input: #bdc3c7;

  --text-color-secondary: hsl(0 0% 40%); /* Material UI default https://mui.com/system/palette/#color */

  --bg-white-sticky: hsl(0 0% 100% / 0.85);
  --bg-white-sticky-strong: hsl(0 0% 100% / 0.92);

  --expected-mui-switch-button-padding: 9px; /* .PrivateSwitchBase-root-197 { padding: 9px; } */
  --expected-mui-icon-size: 1rem; /* .MuiSvgIcon-root { font-size: 1rem } */
  --expected-mui-checkbox-padding: calc(
    var(--expected-mui-switch-button-padding) + var(--expected-mui-icon-size) * 0.125
  );

  --input-spinner-width: 15px;

  font-family: Inter, sans-serif;
}

/*————————————————————————————————————————————————————————————————————————————*/
/* Outer & root elements */

body {
  margin: auto;
}
body,
html {
  height: 100%;
}
/* body > div {
  height: 100%;
  min-height: 100%;
} */

.outer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-left: 60px;
  padding-right: 60px;
  background-color: #eff4ff;
  padding-bottom: 100px;
  height: 100%;
  overflow: auto;
}
@media only screen and (max-width: 600px) {
  .outer {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/*————————————————————————————————————————————————————————————————————————————*/
/* overrides needed as a result */
:global(.MuiAutocomplete-popper) {
  text-align: left; /* This is needed for <Autocomplete> groupBy prop to prevent group headings from inheriting body { text-align: center } */
}
:global(.MuiListSubheader-root) {
  text-align: left; /* for <Select> groupBy prop to prevent group headings from inheriting body { text-align: center } */
}
:global(.MuiSnackbar-root) {
  text-align: left; /* prevent snackbars from inheriting body { text-align: center } */
  white-space: pre-wrap; /* similar to .bmuiScope { ... }. Ideally this would be :root default. */
}

/*————————————————————————————————————————————————————————————————————————————*/

:global(.MuiSnackbar-root),
:global(.MuiSnackbar-root) > :global(.MuiAlert-root),
:global(.MuiSnackbar-root) > :global(.MuiAlert-root) > :global(.MuiAlert-message) {
  max-width: 100%;
}

/*————————————————————————————————————————————————————————————————————————————*/
/* Tag name defaults */

a {
  text-decoration: none;
  color: inherit;
}

button {
  font-family: inherit;
  font-weight: inherit;
}

/*————————————————————————————————————————————————————————————————————————————*/
/* Useful widgets */

:global(.flex-full-height-parent) {
  position: relative;
  margin-top: var(--sidebar-height);
}
:global(.flex-full-height-parent) > :global(.flex-full-height-child) {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

:global(.inline-whoops) {
  color: var(--red);
  padding: 10px;
  font-weight: 500;
}

:global(.inline-loading) {
  color: #777;
  padding: 10px;
  font-weight: 500;
}

:global(.inline-gray) {
  color: #777;
  padding: 10px;
}

:global(.thead-sticky-white) {
  position: sticky;
  top: 0;
  background: var(--bg-white-sticky);
  z-index: 1; /* minimum.. to appear in front of position:relative;z-index:0 things such as mui's <IconButton> */
}

:global(.sticky-first-cell),
:global(.sticky-second-cell) {
  --z-index-for-sticky-nth-cell: 1; /* minimum.. to appear in front of position:relative;z-index:0 things such as mui's <IconButton> */
}

:global(.sticky-first-row) {
  --height-for-header-row: 110px;
}

/* for --z-index-for-sticky-nth-cell to work, the specificness of this rule must be greater than the specificness of the selector that overrides --z-index-for-sticky-nth-cell */
:global(.sticky-first-cell) > div > table > * > tr > *:first-child,
:global(.sticky-second-cell) > div > table > * > tr > *:nth-child(2) {
  position: sticky;
  left: 0;
  z-index: var(--z-index-for-sticky-nth-cell);
  background: var(--bg-white-sticky-strong);
}

:global(.sticky-first-cell):global(.sticky-first-row) > div > table > tbody > tr:first-child > td:first-child,
:global(.sticky-first-cell):global(.sticky-first-row)
  > div
  > table
  > tbody
  > tr:first-child
  > td:nth-child(2) {
  z-index: calc(var(--z-index-for-sticky-nth-cell) + 2);
}

:global(.sticky-first-cell):global(.sticky-first-row)
  > div
  > table
  > tbody
  > tr:first-child
  > td:first-child {
  padding: 10px 20px !important;
}

:global(.sticky-first-row) > div > table > tbody > tr:first-child {
  top: var(--height-for-header-row);
  position: sticky;
  z-index: calc(var(--z-index-for-sticky-nth-cell) + 1);
}

:global(.sticky-first-cell):global(.sticky-second-cell) {
  > div > table > * > tr > *:nth-child(2) {
    left: 40px !important;
  }
}
:global(.sticky-first-cell) > div > table > * > tr > th:first-child,
:global(.sticky-second-cell) > div > table > * > tr > th:nth-child(2) {
  background: var(--bg-thead-sticky-th-sticky);
}

:global(.remove-if-empty):empty {
  display: none !important;
}

/*————————————————————————————————————————————————————————————————————————————*/

:global(.desktop-only) {
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}
